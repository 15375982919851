<template>
  <div class="flex-container non-scrollable h-full">
    <div class="scrollable">
      <div class="flex flex-column">
        <div class="flex justify-content-center align-items-center">
          <Button rounded icon="fa-regular fa-caret-left fa-xl" @click="changeMonth(-1)"></Button>
          <page-title :value="'Notes de frais ' + capitalizeWord(currentMonthNdf.toLocaleDateString(undefined, { month: 'long' }))" class="mx-2"/>
          <Button rounded icon="fa-regular fa-caret-right fa-xl" @click="changeMonth(1)"></Button>
        </div>
        <div class="flex justify-content-center my-2">
          <Button icon="pi pi-plus" label="Nouveau" @click="newDepense"></Button>
        </div>
        <DataView :value="ndfsForMonth">
          <template #empty>
            <div class="my-3 flex justify-content-center">
              <span>Vous n'avez rien à envoyer pour ce mois</span>
            </div>
          </template>
          <template #list="{data}">
            <div class="col-12">
              <div class="flex flex-column xl:flex-row xl:align-items-start p-4 gap-4">
                <div class="flex flex-column sm:flex-row justify-content-between align-items-center xl:align-items-start flex-1 gap-4">
                  <div class="flex flex-column align-items-center sm:align-items-start gap-3">
                    <div class="text-2xl font-bold text-900">{{ getOptionLabel(constants.data.ndfTypeOptions, data.type) }}</div>
                    <div class="flex align-items-center gap-3">
                      <span class="flex align-items-center gap-2">
                          <i class="pi pi-tag"></i>
                          <span class="font-semibold">{{ data.depenses.length}} dépense{{ data.depenses.length >= 2 ? 's' : '' }}</span>
                      </span>
                    </div>
                  </div>
                  <div class="flex sm:flex-column align-items-center sm:align-items-end gap-3 sm:gap-2">
                    <span class="text-2xl font-semibold">{{ new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(data.ttc / 100) }}</span>
                  </div>
                  <Button icon="pi pi-send" label="Envoyer" @click="validateNdf(data)"></Button>
                </div>
              </div>
            </div>
          </template>
        </DataView>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {store} from "@/store";
import PageTitle from "@/components/PageTitle.vue";
import {capitalizeWord, getOptionLabel} from "@/utils/Misc"
export default {
  name: "NdfMobile",
  components: {PageTitle},
  data() {
    return {
      capitalizeWord: capitalizeWord,
      currentMonthNdf: new Date(),
      ndfs: []
    }
  },
  computed: {
    ...mapState({
      me: state => state.auth.me,
      independent: state => state.independent.independent,
      constants: state => state.constants
    }),
    ndfsForMonth () {
      return this.ndfs.filter(ndf => ndf.ikm.length === 0 && ndf.depenses.length && ndf.independentStatus === 'draft' && new Date(ndf.date).getMonth() === this.currentMonthNdf.getMonth() && new Date(ndf.date).getFullYear() === this.currentMonthNdf.getFullYear())
    }
  },
  async created () {
    this.$store.state.misc.loading = true
    await store.dispatch('sidebar/changeMenu', 'Note de frais')
    await this.$store.dispatch('independent/getOneIndependentByUser', this.me.id)
    this.ndfs = await this.$store.dispatch('ndf/getAllNDFSByIndependent', this.independent.id)
    this.$store.state.misc.loading = false
  },
  methods: {
    getOptionLabel,
    async validateNdf (ndf) {
      this.$confirm.require({
        header: 'Envoyer',
        message: 'Vous êtes sur le point d\'envoyer cette note de frais.',
        acceptLabel: 'Confirmer',
        rejectLabel: 'Annuler',
        accept: async () => {
          this.$store.state.misc.loading = true
          const data = {id: ndf.id, independentStatus: 'validated', motifRefus: ''}
          await this.$store.dispatch('ndf/updateNDF', data)
          this.$toast.add({severity: 'success', summary: 'Succès', detail: 'Note de frais envoyée', life: 3000});
          this.ndfs = await this.$store.dispatch('ndf/getAllNDFSByIndependent', this.independent.id)
          this.$store.state.misc.loading = false
        },
      })
    },
    newDepense () {
      this.$router.push({ name: 'ExpenseAccountImage' })
    },
    changeMonth (factor) {
      this.currentMonthNdf = new Date(this.currentMonthNdf.setMonth(this.currentMonthNdf.getMonth() + factor))
    }
  }
}
</script>

<style>
.p-dataview .p-dataview-content {
  background: none !important;
}
</style>
